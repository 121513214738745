import { Menu, Transition } from "@headlessui/react";
import { TollCalculatorDropDownData as data } from "data/data";
import { Fragment, useEffect, useRef, useState } from "react";
import { loaderVisibility } from "@lib/store.ts";

function DropdownSection({
  title,
  links,
  isTwoColumn = false,
  border = false,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const linkItems = links.map((link, index) => (
    <Menu.Item key={index}>
      {({ active }) => (
        <a
          href={window.location.origin + "/" + link.href}
          onClick={()=> window.location.pathname !== "/" + link.href && loaderVisibility.set({ isLoaderVisible: true })}
          className={classNames(
            active ? "bg-blue-50 rounded-md text-tollguru-primary" : "",
            "page-loader block sm:px-4 py-2 text-sm text-tollguru-text hover:text-tollguru-primary hover:bg-blue-50 hover:rounded-md"
          )}>
          {link.title}
        </a>
      )}
    </Menu.Item>
  ));

  return (
    <div className="flex">
      <div className={`pt-1 pb-4`}>
        <div className="sm:px-2 py-2 text-sm text-tollguru-text font-bold">
          {title}
        </div>
        {isTwoColumn ? (
          <div className="grid grid-cols-2 gap-x-16">{linkItems}</div>
        ) : (
          linkItems
        )}
      </div>
    </div>
  );
}
function CombinedDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <Menu as="div" className="relative inline-block" ref={dropdownRef}>
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}>
        <Menu.Button className="flex text-tollguru-text items-center gap-1 text-sm w-full lg:w-auto transition lg:px-3 py-2 hover:text-tollguru-primary  focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-tollguru-primary  focus-visible:outline-none focus-visible:shadow-outline-indigo rounded-md">
          Toll Calculator
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="3"
            stroke="currentColor"
            className={`w-3 h-3 mt-0.5 ${isOpen ? "rotate-180" : ""}`}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
          </svg> */}
        </Menu.Button>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items
            className="absolute mt-1 min-w-[90vw] origin-center bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:min-w-[900px] lg:-left-36 left-0 sm:top-8 top-6 px-8 py-2 overflow-y-auto max-h-[85vh] sm:max-h-none"
            style={{ zIndex: "100" }}>
            <div className="sm:flex w-full sm:items-start sm:justify-between">
              <DropdownSection
                title={data.mostPopularWorldWide.name}
                links={data.mostPopularWorldWide.links}
              />
              <DropdownSection
                title={data.countryWise.name}
                links={data.countryWise.links}
                isTwoColumn={true}
                border={true}
              />
              <DropdownSection
                title={data.mostPopularInUs.name}
                links={data.mostPopularInUs.links}
                border={true}
              />
            </div>
          </Menu.Items>
        </Transition>{" "}
      </div>
    </Menu>
  );
}

// Example usage
export default function TollCalculatorDropDown() {
  return (
    <div className=" w-full">
      <CombinedDropdown data={data} />
    </div>
  );
}
